'use strict'

const _ = require('lodash')

const TPA_SECTION_COMP_TYPE = 'wysiwyg.viewer.components.tpapps.TPASection'
const TPA_SECTION_DATA_TYPE = 'TPA'
const WIX_FORUM_APP_DEF_ID = '14724f35-6794-cd1a-0244-25fd138f9242'
const WIX_FORUM_APP_WIDGET_ID = '1489040e-001f-4631-55a9-2c29b4417126'
const MASTER_PAGE_ID = 'masterPage'

const STRETCHING_LAYOUT_DOCK_VALUE = {left: {vw: 0}, right: {vw: 0}}

function fixWixForumPagesLandingPageInMobileFlag(pageData, appData) {
    const widgetData = appData.widgets[WIX_FORUM_APP_WIDGET_ID]
    const isLandingPageInMobile = _.get(widgetData, 'appPage.landingPageInMobile')
    if (isLandingPageInMobile === true) {
        pageData.isMobileLandingPage = true
    }
}

function fixTpaSectionPageLayoutMobileStretch(pageJson, appData) {
    const tpaSectionComponent = getFirstTPASectionInMobileStructure(pageJson)
    fixTPASectionLayoutIfWixForum(appData, tpaSectionComponent)
}

function fixTPASectionLayoutIfWixForum(appData, tpaSectionComp) {
    const shouldSetStretch = getStretchByDefaultInMobileFlag(appData)
    if (shouldSetStretch === true) {
        _.set(tpaSectionComp.layout, 'dock', STRETCHING_LAYOUT_DOCK_VALUE)
        if (tpaSectionComp.layout.y === 10) {
            tpaSectionComp.layout.y = 0
        }
    }
}

function getFirstTPASectionInMobileStructure(pageJson) {
    let compsToScan = [].concat(_.get(pageJson, 'structure.mobileComponents'))
    while (compsToScan.length > 0) {
        const component = compsToScan.shift()
        if (component && component.componentType === TPA_SECTION_COMP_TYPE) {
            return component
        } else if (component.components) {
            compsToScan = compsToScan.concat(component.components)
        }
    }
}

function getStretchByDefaultInMobileFlag(appData) {
    if (appData) {
        const widgetData = appData.widgets[WIX_FORUM_APP_WIDGET_ID]
        return _.get(widgetData, 'shouldBeStretchedByDefaultMobile')
    }
    return null
}

function getForumApplicationId(clientSpecMap) {
    const appData = _.find(clientSpecMap, {appDefinitionId: WIX_FORUM_APP_DEF_ID})
    return _.get(appData, 'applicationId')
}

module.exports = {
    exec(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel, magicObject) {
        let pageData
        const clientSpecMap = _.get(magicObject, 'clientSpecMap')
        const isExperimentOpen = magicObject.isExperimentOpen('sv_tpaSectionsMobileFSDataFixer')
        const forumApplicationId = getForumApplicationId(clientSpecMap)
        if (forumApplicationId && isExperimentOpen) {
            const appData = _.get(clientSpecMap, forumApplicationId)
            const pageId = _.get(pageJson, 'structure.id')
            if (pageId === MASTER_PAGE_ID) {
                const masterPageData = _.get(pageJson, 'data.document_data')
                pageData = _.find(masterPageData, {tpaApplicationId: forumApplicationId})
                if (pageData) {
                    fixWixForumPagesLandingPageInMobileFlag(pageData, appData)
                }
            } else {
                pageData = _.get(pageJson, ['data', 'document_data'])
                const tpaForumCompData = _.find(pageData, {applicationId: forumApplicationId.toString(),
                    type: TPA_SECTION_DATA_TYPE})


                if (tpaForumCompData) {
                    fixTpaSectionPageLayoutMobileStretch(pageJson, appData)
                }
            }
        }
    }
}