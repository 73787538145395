'use strict'

const _ = require('lodash')
const coreUtils = require('santa-core-utils')

const BG_MEDIA_TYPE = 'BackgroundMedia'

const BG_ITEM_TEMPLATE = {
    alignType: 'center',
    color: '{color_11}',
    fittingType: 'fill',
    scrollType: 'scroll',
    type: 'BackgroundMedia'
}

const PAGE_BACKGROUNDS_TEMPLATE = {
    pageBackgrounds: {
        desktop: {
            custom: true,
            isPreset: true,
            ref: null
        },
        mobile: {
            custom: true,
            isPreset: true,
            ref: null
        }
    }
}

function generateBgItemId(idPrefix, item) {
    return coreUtils.guidUtils.getUniqueId(item.id, '_') + idPrefix
}

function addNewBgItem(id, pageData) {
    pageData[id] = _.assign({id}, BG_ITEM_TEMPLATE)
}

/**
 * For each page, check if mobile bg is defined, and if not, fill in with a basic bg object.
 * @param pageData
 */
function fixUndefinedBackgroundMediaItems(pageData) {
    const pageDataItems = _.filter(pageData, item => item.type === 'Page' || item.type === 'AppPage')

    _.forEach(pageDataItems, item => {
        let id
        _.defaults(item, PAGE_BACKGROUNDS_TEMPLATE)

        if (!item.pageBackgrounds.mobile.ref) {
            id = generateBgItemId('_mobile_bg', item)
            addNewBgItem(id, pageData)
            item.pageBackgrounds.mobile.ref = `#${id}`
        }

        if (!item.pageBackgrounds.desktop.ref) {
            id = generateBgItemId('_desktop_bg', item)
            addNewBgItem(id, pageData)
            item.pageBackgrounds.desktop.ref = `#${id}`
        }
    })
}



/**
 * @exports utils/dataFixer/plugins/backgroundMediaFixer
 * @type {{exec: function}}
 */
module.exports = {
    exec(pageJson) {
        if (!pageJson.structure) {
            return
        }

        const data = pageJson.data.document_data
        const hasBgMedia = _.some(data, {type: BG_MEDIA_TYPE})

        // Run ONLY if we have at least one BackgroundMedia item
        if (hasBgMedia) {
            fixUndefinedBackgroundMediaItems(data)
        }
    }
}