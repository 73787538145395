'use strict'
const _ = require('lodash')
function runOnAllCompsRecursively(comps, funcs) {
    _.forEach(comps, comp => {
        _.forEach(funcs, func => {
            func(comp)
        })
        if (comp.components) {
            runOnAllCompsRecursively(comp.components, funcs)
        }
    })
}

module.exports = runOnAllCompsRecursively