'use strict'

const _ = require('lodash')
const coreUtils = require('santa-core-utils')

module.exports = {
    exec(pageJson) {
        _(pageJson.data.document_data)
            .filter(compData => compData.type === 'AppPart' &&
                    _.includes([coreUtils.blogAppPartNames.CUSTOM_FEED, coreUtils.blogAppPartNames.FEED, coreUtils.blogAppPartNames.SINGLE_POST], compData.appPartName)
            )
            .forEach(compData => {
                _(compData.appLogicCustomizations)
                    .filter(customizationMatches)
                    .forEach(customization => {
                        _.assign(customization, {
                            fieldId: 'dateAndAuthor'
                        })
                    })
            })
    }
}

function customizationMatches(customization) {
    return (
        customization.forType === 'Post' &&
        customization.key === 'comp.hidden' &&
        (customization.fieldId === 'date' || customization.fieldId === 'author') &&
        _.includes(['MediaTop', 'MediaBottom', 'MediaLeft', 'MediaRight', 'MediaZigzag', 'Masonry',
            'MediaTopPage', 'MediaBottomPage', 'MediaLeftPage', 'MediaRightPage', 'MediaZigzagPage', 'MasonryPage',
            'SinglePostMediaBottom', 'SinglePostMediaTop'], customization.view)
    )
}