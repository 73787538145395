'use strict'

const _ = require('lodash')
const fixers = require('../imageService/imageTransformDataFixers')

const migrationFunctions = {
    'BgImageStripProperties'(property) {
        property.fittingType = fixers.cssToFittingType({
            bgSize: property.bgSize,
            bgRepeat: property.bgRepeat
        })
        property.fittingType = fixers.migrateToLegacyFittings(property.fittingType)
        property.alignType = fixers.cssToAlignType(property.bgPosition)
        property.type = 'BgImageStripUnifiedProperties'

        delete property.bgSize
        delete property.bgRepeat
        delete property.bgPosition
        delete property.bgUrl
    }
}

function fixProperties(properties) {
    _.forEach(properties, property => {
        const migrationFunction = migrationFunctions[property.type]
        if (migrationFunction) {
            migrationFunction(property)
        }
    })
}

/**
 * @exports utils/dataFixer/plugins/backgroundsDataFixer
 * @type {{exec: function}}
 */
module.exports = {
    exec(pageJson) {
        const properties = pageJson.data.component_properties
        if (!_.isEmpty(properties)) {
            fixProperties(properties)
        }
    }
}