'use strict'

const coreUtils = require('santa-core-utils')

function createBehaviorsDataItem(behaviors) {
    const dataItem = {
        id: coreUtils.guidUtils.getUniqueId('behavior', '-'),
        type: 'ObsoleteBehaviorsList',
        items: behaviors
    }
    return dataItem
}

module.exports = {
    createBehaviorsDataItem
}