'use strict'

const _ = require('lodash')

// CLNT-4002 - fix document media icon not showing due to storage url change

module.exports = {
    exec(pageJson) {
        if (pageJson.structure.type === 'Page') {
            const components = pageJson.structure.components
            const compProps = pageJson.data.document_data
            _.forEach(components, comp => {
                if (_.includes(comp.componentType, 'DocumentMedia')) {
                    const dataQuery = comp.dataQuery
                    const docCompData = compProps[dataQuery.replace('#', '')]
                    if (docCompData.uri.indexOf('media/') === 0) {
                        docCompData.uri = docCompData.uri.replace('media/', '')
                    }
                }
            })
        }
        return pageJson
    }
}