'use strict'

const _ = require('lodash')
const coreUtils = require('santa-core-utils')

const isHoverBox = comp => comp.componentType === 'wysiwyg.viewer.components.HoverBox'

/**
 * @exports utils/dataFixer/plugins/hoverBoxPropertyDataFixer
 * @type {{exec: function}}
 */
module.exports = {
    exec(pageJson) {
        const desktopHoverBoxes = coreUtils.dataUtils.getAllCompsInStructure(pageJson.structure, false, isHoverBox)
        const mobileHoverBoxes = coreUtils.dataUtils.getAllCompsInStructure(pageJson.structure, true, isHoverBox)

        _.forEach(desktopHoverBoxes, (desktopHoverBox, compId) => {
            const mobileHoverBox = mobileHoverBoxes[compId]
            if (!mobileHoverBox) {
                return
            }

            const mobilePropertyQuery = _.get(mobileHoverBox, ['propertyQuery'])
            const mobileDisplayedModeId = _.get(pageJson, ['data', 'component_properties', mobilePropertyQuery, 'mobileDisplayedModeId'])
            const currentMobileModeIndex = _.findIndex(_.get(mobileHoverBox, ['modes', 'definitions']), {modeId: mobileDisplayedModeId})

            // ensure mobile modes definitions are equal to desktop
            _.set(mobileHoverBox, ['modes', 'definitions'], _.get(desktopHoverBox, ['modes', 'definitions']))

            // ensure
            const newMobileDisplayedModeId = _.get(desktopHoverBox, ['modes', 'definitions', currentMobileModeIndex, 'modeId'])
            _.set(pageJson, ['data', 'component_properties', mobilePropertyQuery, 'mobileDisplayedModeId'], newMobileDisplayedModeId)
        })
    }
}
