'use strict'

const CompsMigrationHelper = require('../helpers/CompsMigrationHelper')
const coreUtils = require('santa-core-utils')

function getEmptyBackground() {
    return {
        type: 'MediaContainerDesignData',
        id: coreUtils.guidUtils.getUniqueId('dataItem', '-'),
        background: {
            id: coreUtils.guidUtils.getUniqueId('dataItem', '-'),
            type: 'BackgroundMedia',
            mediaRef: null,
            alignType: 'center',
            fittingType: 'fill',
            colorOpacity: 0,
            colorOverlay: '',
            colorOverlayOpacity: 1,
            imageOverlay: '',
            scrollType: 'none',
            color: '#FFFFFF'
        }
    }
}


function handleNoDesignDataInColumns(pageJson, cache, mobileView, comp) {
    if (!comp.designQuery && !mobileView) {
        comp.layout.width = 980
        const designDataWithEmptyBackground = getEmptyBackground()
        this.setComponentDesignData(pageJson, comp, designDataWithEmptyBackground, cache)
    }
}

const MIGRATION_MAP = {
    'wysiwyg.viewer.components.StripColumnsContainer': handleNoDesignDataInColumns,
    'wysiwyg.viewer.components.Column': handleNoDesignDataInColumns
}

const migrationHelper = new CompsMigrationHelper(MIGRATION_MAP) // eslint-disable-line santa/no-module-state

module.exports = {exec: migrationHelper.migratePage.bind(migrationHelper)}