'use strict'

const fixPageData = require('./core/fixPageData')
const deprecatedSiteModelMigrater = require('./plugins/deprecatedSiteModelMigrater')
const fixBackgroundData = require('./plugins/fixBackgroundData')
const imageTransformDataFixers = require('./imageService/imageTransformDataFixers')
const anchorCyclesHelper = require('./helpers/anchorCyclesHelper')

module.exports = {
    fix: fixPageData,
    deprecatedSiteModelMigrater,
    fixBackgroundData,
    imageTransformDataFixers,
    anchorCyclesHelper
}