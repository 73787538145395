'use strict'

const _ = require('lodash')

const isJsonForPage = _.matchesProperty(['structure', 'type'], 'Page') // eslint-disable-line santa/no-module-state

module.exports = {
    exec(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel, magicObject) {
        if (_.get(magicObject, 'isSlash') && !isJsonForPage(pageJson)) {
            _.forEach(magicObject.pageIdToResolvedUriSEO, (resolved, pageId) => {
                pageJson.data.document_data[pageId].pageUriSEO = resolved.curr
            })
        }
    }
}