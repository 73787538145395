'use strict'

const _ = require('lodash')
const CompsMigrationHelper = require('../helpers/CompsMigrationHelper')

function migrateGrid(pageJson, cache, isMobile, comp) {
    const gridData = pageJson.data.document_data[comp.dataQuery.slice(1)]
    const compsProps = pageJson.data.component_properties
    const gridProps = compsProps[comp.propertyQuery]

    if (_.has(gridProps, 'dataSource')) {
        const migratingDataSource = {
            type: gridProps.dataSource.type,
            revision: gridProps.dataSource.id
        }
        delete gridProps.dataSource
        const mobilePropsQuery = `mobile_${comp.propertyQuery}`
        if (_.has(compsProps, mobilePropsQuery)) {
            delete compsProps[mobilePropsQuery].dataSource
        }
        _.set(gridData, 'dataSource', migratingDataSource)
    }
}

module.exports = {
    exec(pageJson) {
        const MIGRATION_MAP = {
            'wysiwyg.viewer.components.Grid': migrateGrid
        }
        const migrationHelper = new CompsMigrationHelper(MIGRATION_MAP)
        migrationHelper.migratePage(pageJson)
    }
}