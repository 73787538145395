'use strict'
const CompsMigrationHelper = require('../helpers/CompsMigrationHelper')

const MIGRATION_MAP = {
    'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu': migrateCompToSupportMultipleMenus,
    'wysiwyg.viewer.components.menus.DropDownMenu': migrateCompToSupportMultipleMenus
}
const migrationHelper = new CompsMigrationHelper(MIGRATION_MAP) // eslint-disable-line santa/no-module-state

function migrateCompToSupportMultipleMenus(pageJson, cache, mobileView, comp) {
    const compData = this.getComponentData(pageJson, comp)
    if (!compData || compData.type !== 'CustomMenuDataRef') {
        delete comp.dataQuery
        this.setComponentData(pageJson, comp, {
            menuRef: '#CUSTOM_MAIN_MENU',
            type: 'CustomMenuDataRef'
        }, cache)
    }
}


module.exports = {
    exec: migrationHelper.migratePage.bind(migrationHelper)
}