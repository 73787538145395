'use strict'

const _ = require('lodash')
const coreUtils = require('santa-core-utils')

function isImageDataItem(dataItem) {
    return dataItem.type === 'Image'
}

function hasDuplicatedOriginalImageRef(imagesDataArr, originalImageDataRef) {
    return originalImageDataRef !== 'undefined' && originalImageDataRef !== 'null' && imagesDataArr.length > 1
}

function createNewOriginalImageData(documentData, originalImageDataItemId) {
    const newId = `${originalImageDataItemId}_${coreUtils.guidUtils.getUniqueId()}`
    const newDataItem = _.cloneDeep(documentData[originalImageDataItemId])
    newDataItem.id = newId

    return newDataItem
}

module.exports = {
    exec(pageJson) {
        const documentData = _.get(pageJson, ['data', 'document_data'])

        if (!documentData) {
            return
        }

        _(documentData)
            .pickBy(isImageDataItem)
            .groupBy('originalImageDataRef')
            .pickBy(hasDuplicatedOriginalImageRef)
            .forOwn((imagesDataArr, originalImageDataRef) => {
                const imagesWithDuplicatedOriginalImageRef = _.tail(imagesDataArr)
                const originalImageDataItemId = originalImageDataRef.replace('#', '')

                _.forEach(imagesWithDuplicatedOriginalImageRef, imageDataItem => {
                    const newDataItem = createNewOriginalImageData(documentData, originalImageDataItemId)
                    documentData[newDataItem.id] = newDataItem
                    imageDataItem.originalImageDataRef = `#${newDataItem.id}`
                })
            })
    }
}
