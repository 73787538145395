'use strict'

const _ = require('lodash')

/**
 * @exports utils/dataFixer/plugins/connectionsDataFixer
 * @type {{exec: exec}}
 */
module.exports = {
    exec(pageJson) {
        const pageData = _.get(pageJson, 'data')
        pageData.connections_data = pageData.connections_data || {}
    }
}