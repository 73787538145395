'use strict'

const _ = require('lodash')

const DEFAULT_FORM_COMPONENT_PROPERTIES = {
    type: 'ContactFormProperties',
    hidden_emailFieldLabel: true,
    hidden_nameFieldLabel: true,
    hidden_phoneFieldLabel: false,
    hidden_addressFieldLabel: false,
    hidden_subjectFieldLabel: true,
    hidden_messageFieldLabel: true,
    required_emailFieldLabel: true,
    required_nameFieldLabel: true,
    required_phoneFieldLabel: false,
    required_addressFieldLabel: false,
    required_subjectFieldLabel: false,
    required_messageFieldLabel: false,
    useCookie: true
}

const DEFAULT_FORM_FIELD_LABELS = {
    nameFieldLabel: {
        type: 'string',
        default: 'Name'
    },
    emailFieldLabel: {
        type: 'string',
        default: 'Email'
    },
    phoneFieldLabel: {
        type: 'string',
        default: 'Phone'
    },
    addressFieldLabel: {
        type: 'string',
        default: 'Address'
    },
    subjectFieldLabel: {
        type: 'string',
        default: 'Subject'
    },
    messageFieldLabel: {
        type: 'string',
        default: 'Message'
    },
    submitButtonLabel: {
        type: 'string',
        default: 'Send'
    },
    successMessage: {
        type: 'string',
        default: 'Your details were sent successfully!'
    },
    errorMessage: {
        type: 'string',
        default: 'Please provide a valid email'
    },
    textDirection: {
        type: 'string',
        default: 'left',
        enum: ['left', 'right']
    },
    validationErrorMessage: {
        type: 'string',
        default: 'Please fill in all required fields.'
    },
    onSubmitBehavior: {
        type: 'string',
        default: 'message',
        enum: ['message', 'link']
    }
}

const SKIN_1_FIELDS_ORDER = {
    skinPrefix: 'contactform.',
    fields: ['name', 'email', 'subject', 'phone', 'address', 'message']
}

const SKIN_2_FIELDS_ORDER = {
    skinPrefix: 'wysiwyg.viewer.skins.contactform.',
    fields: ['name', 'email', 'phone', 'address', 'subject', 'message']
}

const DYNAMIC_CONTACT_FORM_TYPE = 'DynamicContactForm'
const OLD_CONTACT_FORM_COMPONENT_TYPE = 'wysiwyg.viewer.components.ContactForm'
const DYNAMIC_CONTACT_FORM_COMPONENT_TYPE = 'wysiwyg.viewer.components.DynamicContactForm'

function cleanQuery(rawQuery) {
    return (rawQuery || '').replace('#', '')
}

function shouldMigrate({isExperimentOpen}, structureData) {
    return (
        isExperimentOpen('sv_contactFormTemplatesMigration') ||
        isExperimentOpen('sv_contactFormFinalMigration') ||
        isExperimentOpen('sv_contactFormFinalMigrationEditor')
    ) && !!structureData
}

function isFormCorrupt(comp, propertyQuery, pageJson) {
    return !_.has(pageJson.data.component_properties, propertyQuery) &&
        comp.componentType === OLD_CONTACT_FORM_COMPONENT_TYPE
}

function fixMigratedContactForm(comp) {
    const propertyQuery = cleanQuery(comp.propertyQuery)
    _.unset(comp, 'propertyQuery')
    _.unset(this.pageJson.data.component_properties, [propertyQuery])
}

function migrateField(fieldName) {
    switch (fieldName) {
        case 'email':
            return {fieldType: 'email', fieldInputType: 'email'}
        case 'phone':
            return {fieldType: 'phone', fieldInputType: 'tel'}
        default:
            return {fieldType: fieldName, fieldInputType: 'text'}
    }
}

function migrateOldFields(skin, oldData, oldProps) {
    const oldFieldsOrder = _.startsWith(skin, SKIN_1_FIELDS_ORDER.skinPrefix) ?
        SKIN_1_FIELDS_ORDER.fields :
        SKIN_2_FIELDS_ORDER.fields

    return _.reduce(oldFieldsOrder, function (res, fieldName) {
        const fieldKey = `${fieldName}FieldLabel`
        const isDisplayed = oldProps[`hidden_${fieldKey}`]

        if (isDisplayed) {
            const migratedData = migrateField(fieldName)

            res.push({
                name: migratedData.fieldType,
                inputType: migratedData.fieldInputType,
                displayLabel: oldData[fieldKey] || _.get(DEFAULT_FORM_FIELD_LABELS[fieldKey], 'default'),
                required: oldProps[`required_${fieldKey}`],
                isDisplayed
            })
        }
        return res
    }, [])
}

function isDynamicContactForm(data) {
    return _.has(data, 'dynamicFields') && _.get(data, 'type') === DYNAMIC_CONTACT_FORM_TYPE
}

function migrateOldContactForm(comp) {
    const compDataQuery = cleanQuery(comp.dataQuery)
    const compPropertyQuery = cleanQuery(comp.propertyQuery)

    const oldData = this.pageJson.data.document_data[compDataQuery]
    const oldProps = isFormCorrupt(comp, compPropertyQuery, this.pageJson) ?
        DEFAULT_FORM_COMPONENT_PROPERTIES :
        this.pageJson.data.component_properties[compPropertyQuery]

    _.unset(comp, 'propertyQuery')
    _.set(comp, 'componentType', DYNAMIC_CONTACT_FORM_COMPONENT_TYPE)

    if (isDynamicContactForm(oldData)) {
        return
    }

    _.assign(this.pageJson.data.document_data[compDataQuery], {
        type: DYNAMIC_CONTACT_FORM_TYPE,
        dynamicFields: migrateOldFields(comp.skin, oldData, oldProps)
    });

    ['name', 'email', 'subject', 'phone', 'address', 'message'].forEach(fieldName => {
        _.unset(this.pageJson.data.document_data, [compDataQuery, `${fieldName}FieldLabel`])
    })

    _.unset(this.pageJson.data.component_properties, compPropertyQuery)
}

function migrateAllOldContactForms(comps) {
    if (!comps) {
        return
    }

    _.forEach(comps, comp => {
        if (comp.componentType === OLD_CONTACT_FORM_COMPONENT_TYPE) {
            migrateOldContactForm.call(this, comp)
        }

        if (comp.componentType === DYNAMIC_CONTACT_FORM_COMPONENT_TYPE) {
            fixMigratedContactForm.call(this, comp)
        }

        migrateAllOldContactForms.call(this, comp.components)
    })
}

module.exports = {
    exec(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel, magicObject) {
        const structureData = pageJson.structure

        if (shouldMigrate(magicObject, structureData)) {
            const allComps = _.concat([], structureData.components || structureData.children, structureData.mobileComponents || [])
            migrateAllOldContactForms.call({pageJson}, allComps)
        }
    }
}
